<div class="content-body mt-menu">

    <div class="ph-item" *ngIf="!showList">
      <div class="ph-col-12">
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row mb-2">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
  </div>
  </div>
  
      <div class="row" *ngIf="showList">
        <div class="col-md-12" >
          <div class="slider-heading-section" *ngIf="list.length > 0">
            <h4>Upcoming</h4>
            <app-single-video-slider
              [playlist]="list"
              [sliderConfig]="sliderConfig"
            ></app-single-video-slider>
          </div>
        </div>
      </div>
  </div>
  