import { Component, Input, OnInit , EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-video-season-slider',
  templateUrl: './video-season-slider.component.html',
  styleUrls: ['./video-season-slider.component.scss']
})
export class VideoSeasonSliderComponent implements OnInit {

  @Input() sliderConfig;
  @Input() playlist;
  @Input() title: string;

  @Output()
  playVideo: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }


  clickVideo(uuid, title, type)
  {
    var data = {
      uuid: uuid,
      title: title,
      type: type
    }
    this.playVideo.emit(data);
  }

}
