import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-featured-slider',
  templateUrl: './featured-slider.component.html',
  styleUrls: ['./featured-slider.component.scss']
})
export class FeaturedSliderComponent implements OnInit {

  @Input() featuredSlider: any;

  activeSlide = 0;
  windowWidth: number;
  isDesktop:boolean = true;


  sliderConfigLarge = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    adaptiveHeight: true,
    infinite: false,
    useTransform: true,
    speed: 400,
    // asNavFor: '.sliderConfigSmall',
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
  }

  sliderConfigSmall = {
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: false,
    infinite: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    }, {
      breakpoint: 640,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
     }
    }, {
      breakpoint: 420,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
   }
    }]
  }

  constructor() { }

  ngOnInit(): void {

    // console.log(this.featuredSlider)
    if(window.innerWidth >= 892 ){
      this.isDesktop = true
    }else{
      this.isDesktop = false
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if(window.innerWidth >= 892 ){
      this.isDesktop = true
    }else{
      this.isDesktop = false
    }
  }


  gotoSlider(index){
    console.log(index)
    
  }

}
