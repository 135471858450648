import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MovieService } from 'src/app/services/movie.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-termofserice',
  templateUrl: './termofserice.component.html',
  styleUrls: ['./termofserice.component.scss']
})
export class TermofsericeComponent {

  constructor(
    private movie: MovieService,
    private sanitizer: DomSanitizer
  ) { }

  showData: boolean = false;
  subs: Subscription[] = [];
  trustedHtml: SafeHtml;


  ngOnInit(): void {
    this.fetchData();
  }


  fetchData()
  {
    this.subs.push(this.movie.getTermsOfUseData().subscribe(res => {
      if(res.code == 200) {
        var content = res.data ? res.data.content : '';
        if(content){
          this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(content);
        }
        this.showData = true;
      }
    })
    )
  }


  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }


}
