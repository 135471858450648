
<nav class="menu-bar my-menu">
  <a
      href="/"
      class="logo"
  >
      <img src="assets/images/logo-abra.png" class="logo-image-all" alt="logo" />
  </a>
  <ul class="menu-list">
    <li><a (click)="goto('/')">Home</a></li>
    <li><a (click)="goto('/category')">CATEGORIES</a></li>
    <li><a (click)="goto('/live')">LIVE</a></li>
    <li><a (click)="goto('/about-us')">ABOUT US</a></li>
    <li><a (click)="goto('/contact-us')">CONTACT US</a></li>
    <li><a [matMenuTriggerFor]="menu2">MORE</a>
      <mat-menu #menu2="matMenu">
        <button mat-menu-item (click)="goto('/privacy')">PRIVACY POLICY</button>
        <button mat-menu-item (click)="goto('/terms-of-use')">TERMS OF USE</button>
      </mat-menu>
    </li>
    <li><a (click)="goto('/search')"> <mat-icon>search</mat-icon></a></li>

    <li  *ngIf="!isLogged"><a (click)="openLogin()"> <mat-icon>person</mat-icon></a></li>

    <li *ngIf="isLogged" class="menu-image">
      <img
        mat-button
        [matMenuTriggerFor]="menu"
        src="assets/images/profile.jpg"
        alt="avatar"
      />

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">LOGOUT</button>
      </mat-menu>
    </li>
  </ul>
  <button class="menu-toggle" (click)="toggleSidebar($event)">☰</button>
</nav>

<div class="sidebar" [class.active]="isSidebarActive">
  <button type="button" class="close-btn" (click)="closeSidebar($event)">✖</button>
  <ul class="sidebar-list">
    <li><a (click)="goto('/')">HOME</a></li>
    <li><a (click)="goto('/category')">CATEGORIES</a></li>
    <li><a (click)="goto('/live')">LIVE</a></li>
    <li><a (click)="goto('/about-us')">ABOUT US</a></li>
    <li><a (click)="goto('/contact-us')">CONTACT US</a></li>
    <li><a (click)="goto('/privacy')">PRIVACY POLICY</a></li>
    <li><a (click)="goto('/terms-of-use')">TERMS OF USE</a></li>
    <li><a (click)="goto('/search')">SEARCH</a></li>
    <li *ngIf="!isLogged"><a (click)="openLogin()">LOGIN</a></li>
    <li *ngIf="isLogged"><a (click)="logout()">LOGOUT</a></li>
  </ul>
</div>


<ng-template #template>
  <div class="modal-body">
    <div class="top-button">
      <button
        type="button"
        class="no-btn"
        (click)="closeLoginWindow()"
        aria-label="Close"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-4 offset-md-4">
          <h1 class="text-center login-title">Sign in to continue</h1>
          <div class="account-wall">
            <img
              class="profile-img logo-image-all"
              src="assets/images/logo-abra.png"
              alt=""
            />
            <form
              class="form-signin"
              (ngSubmit)="loginuser(signinForm.value)"
              [formGroup]="signinForm"
            >
              <input
                formControlName="email"
                type="email"
                name="email"
                class="form-control mb-2"
                placeholder="Email"
                required
                autofocus
              />
              <input
                formControlName="password"
                name="password"
                type="password"
                class="form-control"
                placeholder="Password"
                required
              />
              <p class="newButton" *ngIf="showerrorBlock">* {{ errorText }}</p>
              <button
                class="btn btn-lg btn-primary btn-block btn-custom"
                type="submit"
                [disabled]="!signinForm.valid || showLoading"
              >
                Sign in
              </button>
            </form>
            <div class="form-signin">
              <a
                *ngIf="!showLoading"
                class="btn btn-lg btn-primary btn-block btn-google"
                (click)="loginGoogleUser()"
              >
              <img src="assets/images/google.png" class="logo-image-google" alt="logo" />  Sign in with Google
            </a>
              <a
                *ngIf="showLoading"
                class="btn btn-lg btn-primary btn-block btn-google-lock"
              >
              <img src="assets/images/google.png" class="logo-image-google" alt="logo" />   Sign in with Google
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
