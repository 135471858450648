// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL: 'https://abraadmin.margalla.net/api',
  WEB_URL: 'https://abraadmin.margalla.net',
  firebase: {
    apiKey: "AIzaSyB7SOCzC2p30WG90zl1DIlAxvKudWhjoX4",
    authDomain: "abra-c2fd1.firebaseapp.com",
    projectId: "abra-c2fd1",
    storageBucket: "abra-c2fd1.appspot.com",
    messagingSenderId: "166606950521",
    appId: "1:166606950521:web:598624989b94a629eac4a8",
    measurementId: "G-ZGX0NCYLHX"
  }
  // URL: 'https://www.mymaxott.club/api',
  // WEB_URL: 'https://www.mymaxott.club',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
