<div class="container mt-menu">

    <h1>Terms of Use</h1>

    <div class="ph-item" *ngIf="!showData">
        <div class="ph-col-12">

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

        </div>
    </div>


    <div *ngIf="showData" [innerHTML]="trustedHtml"></div>

    <!-- <div class="content-text">

        <p>Welcome to Abra Media, a Pakistani streaming platform offering free access to a wide range of dramas and movies. By using this application, you agree to the following terms and conditions:</p>

        <h4>Acceptance of Terms:</h4>
        
        <p>By accessing or using the Abra Media application, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>

        <h4>Eligibility:</h4>
        
        <p>You must be at least 18 years old or have the legal capacity to enter into contracts to use this application. If you are accessing or using  Media on behalf of a company or organization, you represent and warrant that you are authorized to do so.</p>
        
        <h4>User Accounts:</h4>
        
        <p>You may need to create an account to access certain features of the application. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
        
        <h4>Content and Licensing:</h4>
        
        <p>All content provided by Abra Media is protected by copyright, trademark, and other laws. You agree not to reproduce, distribute, modify, or create derivative works from any content without the express written consent of Abra Media</p>

        <h4>User Conduct:</h4>
        
        <p>You agree not to use the application for any unlawful or prohibited purpose. This includes, but is not limited to, engaging in any behavior that disrupts the normal operation of the application, transmitting any harmful code, or attempting to gain unauthorized access to any part of the application.</p>
        
        <h4>Advertising and Promotions:</h4>
        
        <p>Abra Media may display advertisements and promotions. By using the application, you agree to receive such communications.</p>
        
        <h4>Third-Party Links:</h4>
        
        <p>Abra Media may contain links to third-party websites or resources. We are not responsible for the availability or accuracy of such external content, and you acknowledge and agree that we shall not be held liable for any loss or damage arising from your use of such content.</p>
        
        <h4>Termination:</h4>
        
        <p>Abra Media reserves the right to suspend or terminate your account at any time for any reason, without notice.</p>

        <h4>Disclaimer of Warranties:</h4>
        
        <p>The application is provided "as is" and Abra Media makes no warranties, express or implied, regarding its functionality, accuracy, or availability.</p>
        
        <h4>Limitation of Liability:</h4>
        
        <p>In no event shall Abra Media be liable for any indirect, consequential, special, or incidental damages arising out of or in connection with your use of the application.</p>
        
        <h4>Governing Law:</h4>
        
        <p>These Terms of Use shall be governed by and construed in accordance with the laws of Pakistan.</p>
        
        <h4>Changes to Terms:</h4>

        <p>Abra Media reserves the right to update or modify these Terms of Use at any time. Continued use of the application after any such changes shall constitute your consent to such changes.</p>
        
        <h4>How users may request deletion of that data</h4>

        <p>Delete the profile and its associated information</p>
        <p>User can visit Profile > Edit Profile > Delete Account The user receives intimation that after the deletion of the account, the user will not be able to retrieve the data and all the data will be deleted permanently.</p>

        <p>If you have any questions or concerns regarding these Terms of Use, please contact us at</p>
        <p>info@abraott.com</p>
        

    </div> -->
</div>
