<div class="ph-item mt-menu"  *ngIf="!showData">
  <div class="ph-col-12">
    <div class="row" style="margin-top: 80px">
      <div class="col-md-4">
        <div class="ph-button"></div>
      </div>
    </div>
    <div class="row" style="margin-top: 20px">
      <div class="col-md-12">
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 20px">
      <div class="col-md-6">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-6">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px">
      <div class="col-md-2">
        <div class="ph-button"></div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="ph-row ">
        <div class="ph-col-12 big"></div>
        </div>
      </div>
    </div>


    <div class="row" style="margin-top: 20px">
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
    </div>
  </div>

  </div>
</div>


<div class="header__banner"
[ngStyle]="{
'background-image' : 'linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.3)), url('+posterImage+')'
}"
*ngIf="showData"
>
</div> 




<div class="header__banner__mobile"
[ngStyle]="{
'background-image' : 'url('+posterImage+')'
}"
*ngIf="showData"
>
</div>
<div class="content-body mt-menu" *ngIf="showData">
    <h1 class="content__title">
        {{content.title}}
    </h1>

    <div class="content__sub_section">
         <p>
            {{secondsToHms(content.duration)}} 
            <span class="mr-2" ></span> 
            {{content.year}} 
            <span class="mr-2" ></span> 
            {{content.age_group}} 
         </p>

         <p class="content__synopsis_half" *ngIf="!showFull" (click)="toggleShowFull()">{{content.synopsis}}</p>
         <p class="content__synopsis_full" *ngIf="showFull">{{content.synopsis}}</p>


         <div class="header__buttons">
  
            <button class="play-button" (click)="OpenLogin()" *ngIf="released">
              <mat-icon>arrow_right</mat-icon>
              <span>{{playText}}</span>
            </button>

            <button class="play-button" *ngIf="!released">
              <!-- <mat-icon>arrow_right</mat-icon> -->
              <span>COMING {{moment(content.release_date).format('MMMM DD YYYY')}}</span>
            </button>
    
          </div>

          
          <p *ngIf="content.actors.length > 0">
              <span class="content__sub__title">Starring : </span> <span *ngFor="let actor of content.actors; let i = index">{{actor.name}}{{ i != (content.actors.length - 1) ? ' , ': '' }}</span>
          </p>
          <p *ngIf="content.directors.length > 0">
              <span class="content__sub__title">Directors : </span> <span *ngFor="let director of content.directors; let i = index">{{director.name}}{{ i != (content.directors.length - 1) ? ' , ' : '' }}</span>
          </p>
          <p *ngIf="content.writers.length > 0">
              <span class="content__sub__title">Writers : </span> <span *ngFor="let writer of content.writers; let i = index">{{writer.name}}{{ i != (content.writers.length - 1) ? ' , ': '' }}</span>
          </p>
          <p *ngIf="content.hosts.length > 0">
              <span class="content__sub__title">Host : </span> <span *ngFor="let host of content.hosts; let i = index">{{host.name}}{{ i != (content.hosts.length - 1) ? ' , ': '' }}</span>
          </p>
          <p *ngIf="content.producers.length > 0">
              <span class="content__sub__title">Producers : </span> <span *ngFor="let producer of content.producers; let i = index">{{producer.name}}{{ i != (content.producers.length - 1) ? ' , ': '' }}</span>
          </p>
          <p *ngIf="content.production_houses.length > 0">
              <span class="content__sub__title">Production House : </span> <span *ngFor="let production_house of content.production_houses; let i = index">{{production_house.name}}{{ i != (content.production_houses.length - 1) ? ' , ': '' }}</span>
          </p>

          <p *ngIf="content.languages.length > 0">
              <span class="content__sub__title">Languages : </span> <span *ngFor="let language of content.languages; let i = index">{{language.name}}{{ i != (content.languages.length - 1) ? ' , ': '' }}</span>
          </p>

          <p *ngIf="content.categories?.length > 0">
              <span class="content__sub__title">Categories : </span> <span *ngFor="let category of content.categories; let i = index">{{category.name}}{{ i != (content.categories.length - 1) ? ' , ': '' }}</span>
          </p>




          <div class="slider-heading-section" *ngIf="season1.length > 0">
            <h4>Season 1</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season1" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season2.length > 0">
            <h4>Season 2</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season2" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season3.length > 0">
            <h4>Season 3</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season3" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season4.length > 0">
            <h4>Season 4</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season4" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season5.length > 0">
            <h4>Season 5</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season5" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season6.length > 0">
            <h4>Season 6</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season6" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season7.length > 0">
            <h4>Season 7</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season7" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season8.length > 0">
            <h4>Season 8</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season8" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season9.length > 0">
            <h4>Season 9</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season9" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="season10.length > 0">
            <h4>Season 10</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="season10" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

          <div class="slider-heading-section" *ngIf="related.length > 0">
            <h4>Related Videos</h4>
            <app-video-season-slider (playVideo)="selectVideo($event)" [playlist]="related" [sliderConfig]="sliderConfig"></app-video-season-slider>
          </div>

    </div>
</div>



<ng-template #player>
     
    <div class="modal-body">
      <div class="top-button">
      <button type="button" class="no-btn" (click)="playerModalRef.hide()" aria-label="Close">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h4>{{content.title}}{{selectedTitle}}</h4>
    </div>
      <div class="player__section">
        
        <p class="videoSection" [innerHTML]="videoEmbed | safe: 'html'" ></p>
      </div>
    </div>
  </ng-template>
