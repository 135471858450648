import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MovieService } from 'src/app/services/movie.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchValue = '';
  showlist = false;
  showSpinner = false;
  showNotFound = false;
  contents = [];

  constructor(private movie: MovieService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  search() {
    this.showlist = false;
    this.showSpinner = true;
    this.showNotFound = false;
    this.cdRef.detectChanges();
    if (this.searchValue) {
      var search = this.searchValue.split(' ');
      var data = {
        search
      }
      this.movie.searchContent(data).subscribe((res) => {
        console.log(res);
        this.showSpinner = false;
        if (res.code == 200) {
          if(res.data.length > 0){
            this.contents = res.data;
            this.showlist = true;
          }else{
            this.showNotFound = true;
          }
        }
      });
    } else {
      this.showlist = false;
      this.showSpinner = false;
      this.showNotFound = false;
    }

    this.cdRef.detectChanges();
  }
}
