<div class="ph-item mt-menu" *ngIf="!showFeaturedSlider">
  <div class="ph-col-12">
    <div class="row" style="margin-top: 80px">
      <div class="col-md-4">
        <div class="ph-button"></div>
      </div>
    </div>
    <div class="row" style="margin-top: 20px">
      <div class="col-md-2">
        <div class="ph-button"></div>
      </div>
    </div>

    <div class="row" style="margin-top: 120px">
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
      </div>
      <div class="col-md-2">
        <div class="ph-picture"></div>
    </div>
  </div>

  <div class="row" style="margin-top: 40px">
    <div class="col-md-4">
      <div class="ph-button"></div>
    </div>
  </div>

  </div>
</div>

<div class="featured-section" *ngIf="showFeaturedSlider">
  <app-featured-slider [featuredSlider]="featuredSlider"></app-featured-slider>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div
        class="body"
        style="z-index: 1; position: relative"
        *ngIf="showFeaturedSlider"
      >
        <app-slider
          [playlists]="playlists"
          [sliderConfig]="sliderConfig"
          [sliderConfigVert]="sliderConfigVert"
        ></app-slider>
      </div>
    </div>
  </div>
</div>
