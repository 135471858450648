<div class="container mt-menu">

    <h1>About Us</h1>
    <div class="ph-item" *ngIf="!showData">
        <div class="ph-col-12">

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-8 big"></div>
                <div class="ph-col-4 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-10 big"></div>
                <div class="ph-col-2 empty big"></div>
            </div>
            <div class="ph-row mb-2 mt-2">
                <div class="ph-col-9 big"></div>
                <div class="ph-col-3 empty big"></div>
            </div>

        </div>
    </div>


    <div *ngIf="showData" [innerHTML]="trustedHtml"></div>


    <!-- <h1>Prosper Ventures Pvt. Ltd.</h1>
    <br />

    <h4>Overview:</h4>

    <p>Prosper Ventures Pvt. Ltd., headquartered in Islamabad, Pakistan, is a dynamic and forward-thinking company dedicated to delivering innovative technological solutions across South Asia and the Middle East. With a focus on fintech, digital entertainment, and cloud services, we are committed to enhancing the digital experience for our diverse audience.</p>

    <h4>Mission:</h4>
    <p>To empower individuals and businesses with cutting-edge technology solutions that drive efficiency, convenience, and growth in an increasingly digital world.</p>
    <h4>Vision:</h4>
    <p>To be a leading technology company in South Asia and the Middle East, recognized for our innovation, reliability, and customer-centric approach.</p>

    <h4>Products and Services:</h4>

    <p>1. BeePay:</p>

    <p>BeePay is a groundbreaking cashless fintech startup designed to revolutionize the way transactions are made. Offering seamless, secure, and instant payment solutions, BeePay aims to simplify financial transactions for individuals and businesses. With an intuitive interface and robust security measures, BeePay is poised to become the preferred choice for cashless payments in the region.</p>

    <p>2. Abra:</p>
    <p>Abra is an Over-The-Top (OTT) platform that delivers a diverse range of digital entertainment content to users. From movies and TV shows to exclusive web series and live events, Abra provides high-quality streaming services that cater to the varied tastes of our audience. With user-friendly features and a vast content library, Abra ensures an unparalleled entertainment experience.</p>

    <p>3. Burj:</p>
    <p>Burj offers state-of-the-art cloud services tailored to meet the needs of modern businesses. Our cloud solutions include data storage, computing power, and advanced security features, enabling organizations to scale efficiently and securely. With Burj, businesses can leverage the power of cloud technology to optimize their operations and drive innovation.</p>


    <h4>Target Audience:</h4>
    <p>Prosper Ventures Pvt. Ltd. targets the rapidly growing digital markets of South Asia and the Middle East. Our products and services are designed to cater to the needs of tech-savvy individuals, forward-thinking businesses, and the burgeoning startup ecosystem in these regions.</p>

    <h4>Team:</h4>
    <p>Our team is a vibrant mix of young, energetic leaders and seasoned professionals. The leadership team at Prosper Ventures Pvt. Ltd. comprises individuals with extensive experience in technology, finance, and digital media. Our engineers and solutions architects are industry veterans who bring a wealth of knowledge and expertise to the table, ensuring that our products and services are built to the highest standards of quality and innovation.</p>

    <h4>Commitment to Innovation:</h4>

    <p>At Prosper Ventures Pvt. Ltd., innovation is at the heart of everything we do. We continually invest in research and development to stay ahead of industry trends and deliver cutting-edge solutions that meet the evolving needs of our customers.</p>

    <h4>Conclusion:</h4>

    <p>Prosper Ventures Pvt. Ltd. is dedicated to making a significant impact in the tech landscape of South Asia and the Middle East. With our innovative products, expert team, and customer-focused approach, we are well-positioned to drive the next wave of digital transformation in the region.</p>

    <h4>Contact Us:</h4>

    <p>info@prosperventures.pk</p> -->

</div>
