<div class="content-body mt-menu">

    <div class="slider-heading-section">
        <h4>Live</h4>
    </div>

  
    <div class="ph-item" *ngIf="!showList">
      <div class="ph-col-12">
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row mb-2">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
  </div>
  </div>
  
 
  </div>
  

  <div class="row ml-3 mr-3" *ngIf="showList">
    
    <div class="col-md-2 mt-3 mb-3 col-4 col-sm-4" *ngFor="let c of list">
      <div class="slick-item" >
        <div class="single-image"  [routerLink]="['/video/', c.pivot.content_id ]" >
            <div class="single-image-inside">
                <img src="{{c.thumbnail_vertical}}" alt="{{c.title}}">
                <p class="title-vdo">{{c.title}}</p>
            </div>
        </div>
    </div>
    </div>
</div>