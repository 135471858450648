<nav class="menu-bar my-menu">
    <a
        href="/"
        class="logo"
    >
        <img src="assets/images/logo-abra.png" class="logo-image-all" alt="logo" />
    </a>
    <!-- <div class="logo">Logo</div> -->
    <ul class="menu-list">
      <li><a href="#">Home</a></li>
      <li><a href="#">CATEGORIES</a></li>
      <li><a href="#">UPCOMING</a></li>
      <li><a href="#">PRIVACY POLICY</a></li>
      <li><a href="#">TERMS OF USE</a></li>
      <li><a href="#"> <mat-icon>search</mat-icon></a></li>
      <li><a href="#"> <mat-icon>person</mat-icon></a></li>
    </ul>
    <button class="menu-toggle" (click)="toggleSidebar($event)">☰</button>
  </nav>
  
  <div class="sidebar" [class.active]="isSidebarActive">
    <button type="button" class="close-btn" (click)="closeSidebar($event)">✖</button>
    <ul class="sidebar-list">
      <li><a href="#" (click)="closeSidebar($event)">HOME</a></li>
      <li><a href="#" (click)="closeSidebar($event)">CATEGORIES</a></li>
      <li><a href="#" (click)="closeSidebar($event)">UPCOMING</a></li>
      <li><a href="#" (click)="closeSidebar($event)">PRIVACY POLICY</a></li>
      <li><a href="#" (click)="closeSidebar($event)">TERMS OF USE</a></li>
      <li><a href="#" (click)="closeSidebar($event)">SEARCH</a></li>
    </ul>
  </div>
  