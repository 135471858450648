import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MovieService } from 'src/app/services/movie.service';

@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss']
})
export class LivestreamComponent {

  showList:boolean = false;
  list:any = [];
  subs: Subscription[] = [];


  constructor(
    private movie: MovieService
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }


  fetchData()
  {
    this.subs.push(this.movie.getLiveStreams().subscribe(res => {
      if(res.code == 200) {
        this.list = res.data.contents;
        this.showList = true;
      }
    })
    )
  }


  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }

}
