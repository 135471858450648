import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MovieService } from 'src/app/services/movie.service';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss']
})
export class UpcomingComponent {

  list:any = [];
  showList:boolean = false;
  subs: Subscription[] = [];
  page = 1;

  sliderConfig = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    useTransform: false,
    variableWidth: false,
    arrow: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
      dots: false,
      arrows: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          infinite: false,
          slidesToShow:3 ,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  }

  constructor(
    private movie: MovieService
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }


  fetchData()
  {
    this.subs.push(this.movie.getUpcomings().subscribe(res => {
      if(res.code == 200) {
        this.list = res.data;
        this.showList = true;
      }
    })
    )
  }


  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }
}
