<div class="movie">
    <div class="movie__images">
        <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel">
                    <div class="slick-item" *ngFor="let m of playlist" ngxSlickItem>
                        <div class="single-image"  (click)="clickVideo(m.video_uuid, m.title, m.type)" >
                            <div class="single-image-inside">
                                <img src="{{m.thumbnail}}" alt="{{m.title}}">
                            </div>
                        </div>
                        <p class="title-vdo">{{m.title}}</p>
                    </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
