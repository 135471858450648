import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MovieService } from 'src/app/services/movie.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import * as moment from "moment";

@Component({
  selector: 'app-single-screen',
  templateUrl: './single-screen.component.html',
  styleUrls: ['./single-screen.component.scss'],
})
export class SingleScreenComponent implements OnInit {
  showData: boolean = false;
  id: any;
  subs: Subscription[] = [];
  content: any = [];
  videos: any = [];
  posterImage: string = '';
  showFull: boolean = false;
  released: boolean = false;
  authSave: any;
  isLogged = false;
  localData:any = [];
  singleMovie:any = [];
  videoEmbed = '';
  vdolink = '';
  namegen = '';
  videoID = '';
  playText = '';
  selectedUUID = '';
  related = [];
  season1 = [];
  season2 = [];
  season3 = [];
  season4 = [];
  season5 = [];
  season6 = [];
  season7 = [];
  season8 = [];
  season9 = [];
  season10 = [];
  selectedTitle = '';
  shouldGetEmbed = false;

  moment: any = moment;

  sliderConfig = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 0,
    useTransform: false,
    variableWidth: false,
    arrow: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
      dots: false,
      arrows: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          infinite: false,
          slidesToShow:4 ,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    ]
  }

  @ViewChild('player') player;
  playerModalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute, 
    private movie: MovieService,
    private modalService: BsModalService,
    private cdRef:ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    if (isPlatformBrowser(this.platformId)) {
      
      this.getVisitorData();
      this.movie.userDataChanges.subscribe(x => {
          if(x){
            this.isLogged = true;
          }else{
            this.isLogged = false;
          }
      })


    }

  }

  getVisitorData(): void {
    this.subs.push(
      this.movie.getSingleVideoForVisitor(this.id).subscribe((res) => {
       
        if (res.code == 200) {
          this.content = res.data.content;
          this.videos = res.data.videos;
          this.posterImage = this.content.poster;
          this.showData = true;
          let now = new Date();
          let contentDate = new Date(res.data.content.release_date);

          if(now > contentDate){
            this.released = true;
          }else{
            this.released = false;
          }


           if(this.content.type == 1){
            this.videos.map( item => {
                if(item.type == 1){
                  this.playText = 'Play';
                }else if(item.type == 3) {
                  this.playText = 'Play Teaser';
                }else if(item.type == 4) {
                  this.playText = 'Play Trailer';
                }
                this.singleMovie = item;
                this.content.duration = item.duration;
                this.selectedUUID = item.video_uuid;

                if(!item.hls_link && item.embed_link){
                  this.shouldGetEmbed = true
                }else{
                  this.shouldGetEmbed = false
                }
              
             })
           }else if(this.content.type == 2){
            this.season1 = this.videos.filter(res => res.type == 2 && res.season == 1)
            this.season2 = this.videos.filter(res => res.type == 2 && res.season == 2)
            this.season3 = this.videos.filter(res => res.type == 2 && res.season == 3)
            this.season4 = this.videos.filter(res => res.type == 2 && res.season == 4)
            this.season5 = this.videos.filter(res => res.type == 2 && res.season == 5)
            this.season6 = this.videos.filter(res => res.type == 2 && res.season == 6)
            this.season7 = this.videos.filter(res => res.type == 2 && res.season == 7)
            this.season8 = this.videos.filter(res => res.type == 2 && res.season == 8)
            this.season9 = this.videos.filter(res => res.type == 2 && res.season == 9)
            this.season10 = this.videos.filter(res => res.type == 2 && res.season == 10)
            var found = false
            this.videos.map(res => {
              if(!found){
                  if(res.sort > 0) {
                    this.content.duration = res.duration;
                    this.playText = 'Season '+res.season+' Episode 1';
                    this.selectedUUID = res.video_uuid;
                    if(!res.hls_link && res.embed_link){
                      this.shouldGetEmbed = true
                    }else{
                      this.shouldGetEmbed = false
                    }
                    this.selectedTitle = ' - '+res.video.title;
                    found = true;
                  }
              }
            })
          }




           this.related = this.videos.filter(res => res.type != 1 && res.type != 2)

           this.content.duration = this.content.duration ? this.content.duration : this.videos[0].duration ;
           this.selectedUUID = this.selectedUUID ? this.selectedUUID : this.videos[0].video_uuid ;
        }
      })
    );
  }


  toggleShowFull() {
    this.showFull = true;
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }

  secondsToHms(d) {
    d = Number(d);
    if (d < 60) {
      return d + 's';
    }
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var hDisplay = h > 0 ? h + (h == 1 ? 'h ' : 'h ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? 'm' : 'm') : '';
    return hDisplay + mDisplay;
  }

  selectVideo(data)
  {
    this.selectedUUID = data.uuid;

    if(!data.hls_link && data.embed_link){
      this.shouldGetEmbed = true
    }else{
      this.shouldGetEmbed = false
    }
    if(data.type != 1){
      this.selectedTitle = ' - '+data.title;
    }else{
      this.selectedTitle = '';
    }
    this.OpenLogin();
  }

  OpenLogin()
  {

    if(this.isLogged){

      if(this.shouldGetEmbed){

        this.playerModalRef = this.modalService.show(this.player);
        this.playerModalRef.setClass("full-screen-modal");

        this.movie.getSingleVideoEmbedCode(this.selectedUUID).subscribe((res) => {

          if(res.code == 200){
            this.videoEmbed =  res.data.embed_link;
            this.cdRef.detectChanges()
       
          }

        })

      }else{
        var user = this.movie.userData ? this.movie.userData.id : 0;
        this.videoEmbed =  `<iframe src="${environment.WEB_URL}/embed/${this.selectedUUID}?u=${user}" width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`;
        this.cdRef.detectChanges()
        this.playerModalRef = this.modalService.show(this.player);
        this.playerModalRef.setClass("full-screen-modal");
      }


    }else{
      this.movie.triggerLogin();
    }
  }


}
