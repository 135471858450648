import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { SliderComponent } from './components/slider/slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SingleScreenComponent } from './components/single-screen/single-screen.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SafePipe } from './safe.pipe';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { MovieService } from './services/movie.service';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { SingleVideoSliderComponent } from './components/single-video-slider/single-video-slider.component';
import { SearchComponent } from './components/search/search.component';
import { CategoryComponent } from './components/category/category.component';
import { VideoSeasonSliderComponent } from './components/video-season-slider/video-season-slider.component';
import { FeaturedSliderComponent } from './components/featured-slider/featured-slider.component';
import { UpcomingComponent } from './components/upcoming/upcoming.component';
import { TermofsericeComponent } from './components/termofserice/termofserice.component';
import { MenuComponent } from './menu/menu.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { LivestreamComponent } from './components/livestream/livestream.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';


@NgModule({
  declarations: [
    AppComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NotFoundComponent,
    SingleScreenComponent,
    SafePipe,
    CategoryComponent,
    PrivacyComponent,
    SingleVideoSliderComponent,
    SearchComponent,
    VideoSeasonSliderComponent,
    FeaturedSliderComponent,
    UpcomingComponent,
    TermofsericeComponent,
    MenuComponent,
    LivestreamComponent,
    AboutUsComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatBadgeModule,
    SlickCarouselModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    Ng2TelInputModule,
    ModalModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [MovieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
