import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeaturedSlider } from '../models/featured-slider.model';
import { Movies } from '../models/movies.model';
import { Auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';



@Injectable({
  providedIn: 'root',
})
export class MovieService {
  private URL = environment.URL;
  public loginMenu: string = '';
  private auth = inject(Auth);

  public loginMenuChanges: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.loginMenu);
  public userData = null;
  public userDataChanges: BehaviorSubject<any> = new BehaviorSubject<string>(
    this.userData
  );

  constructor(private http: HttpClient) {}

  // getTokenHeader(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (this.userData) {
  //       let tokenHeader = new HttpHeaders({
  //         Authorization: 'Bearer ' + this.userData.token,
  //       });
  //       tokenHeader.append('Access-Control-Allow-Origin', '*');
  //       tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
  //       tokenHeader.append(
  //         'Access-Control-Allow-Methods',
  //         'GET,POST,PUT,DELETE,OPTIONS'
  //       );
  //       tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
  //       tokenHeader.append('Content-Type', 'application/json');
  //       resolve(tokenHeader);
  //     } else {
  //       let tokenHeader = new HttpHeaders();
  //       tokenHeader.append('Access-Control-Allow-Origin', '*');
  //       tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
  //       tokenHeader.append(
  //         'Access-Control-Allow-Methods',
  //         'GET,POST,PUT,DELETE,OPTIONS'
  //       );
  //       tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
  //       tokenHeader.append('Content-Type', 'application/json');
  //       resolve(tokenHeader);
  //     }
  //   });
  // }

    //in authService file 
    getTokenHeader(): Promise<any> {
      return new Promise((resolve,reject)=>
     {
      if(this.auth.currentUser){
        this.auth.currentUser?.getIdToken(true)
        .then(token => {
          if(token){
            let tokenHeader = new HttpHeaders({
              'Authorization': 'Bearer '+token
            });
            tokenHeader.append('Access-Control-Allow-Origin', '*');
            tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
            tokenHeader.append('Access-Control-Allow-Methods', 'GET,POST,PUT,DELETE,OPTIONS');
            tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
            tokenHeader.append('Content-Type', 'application/json');
    
            resolve(tokenHeader)
          }else{
            let tokenHeader = new HttpHeaders({
              'Access-Control-Allow-Origin': '*'
            });
            // tokenHeader.append('Access-Control-Allow-Origin', '*');
            tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
            tokenHeader.append('Access-Control-Allow-Methods', 'GET,POST,PUT,DELETE,OPTIONS');
            tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
            tokenHeader.append('Content-Type', 'application/json');
            resolve(tokenHeader)
          }
        }).catch(err => {
          let tokenHeader = new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
          });
          // tokenHeader.append('Access-Control-Allow-Origin', '*');
          tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
          tokenHeader.append('Access-Control-Allow-Methods', 'GET,POST,PUT,DELETE,OPTIONS');
          tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
          tokenHeader.append('Content-Type', 'application/json');
          resolve(tokenHeader)
        });
      }else{
        let tokenHeader = new HttpHeaders({
          'Access-Control-Allow-Origin': '*'
        });
        // tokenHeader.append('Access-Control-Allow-Origin', '*');
        tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
        tokenHeader.append('Access-Control-Allow-Methods', 'GET,POST,PUT,DELETE,OPTIONS');
        tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
        tokenHeader.append('Content-Type', 'application/json');
        resolve(tokenHeader)
      }
    
  
      });
    }

  getFeaturedSlider(): Observable<any> {
    return this.http.get<any>(`${this.URL}/homeplaylist`, {});
  }

  getLoginUser(): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .get(`${this.URL}/getUserAbra`, { headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  getLoginUserSocial(): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .post(`${this.URL}/registerUserSocial`,{} ,{ headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  getSingleVideoForVisitor(data): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .get(`${this.URL}/getContent/` + data, { headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  getSingleVideoEmbedCode(data): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .get(`${this.URL}/getEmbedCode/` + data, { headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  logout(): Observable<any> {
    const promise = this.auth.signOut()
    .then((res) => {return res})
  return from(promise)
  }

  searchContent(data): Observable<any> {
    return new Observable(observer => {
      this.getTokenHeader()
        .then(headers => {
          return this.http.post(`${this.URL}/search`,data, {headers: headers} )
              .subscribe(res => {
                observer.next(res);
                observer.complete();
              })
        })
        .catch(( error: any ) => {
          observer.error(error);
          observer.complete();
        });
    });
  }


  checkLogin() {
    var localData = JSON.parse(localStorage.getItem('margalla-profile'));

    if (localData) {
      this.triggerStatusChange(localData);
    } else {
      this.triggerStatusChange(null);
    }
  }

  login(data): Observable<any> {
    return new Observable((observer) => {
      return this.http.post(this.URL + '/login', data).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  firebaseLogin(data): Observable<any> {
    const promise = signInWithEmailAndPassword(this.auth,data.email, data.password)
    .then((res) => {return res})
  return from(promise)
  }

  googleSignin(): Observable<any> {
    const promise = signInWithPopup(this.auth, new GoogleAuthProvider)
    .then((res) => {return res})
  return from(promise)
  }

  signUp(email: string, password: string) {
    return;
  }



  triggerLogin() {
    this.loginMenuChanges.next('open');
  }

  triggerStatusChange(userData) {
    this.userData = userData;
    this.userDataChanges.next(userData);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getCategoryContent`,
      {}
    );
  }

  getUpcomings(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getUpcomingData`,
      {}
    );
  }

  getAboutUsData(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getWebpage/about-us`,
      {}
    );
  }

  getPrivacyPolicyData(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getWebpage/privacy-policy`,
      {}
    );
  }

  getTermsOfUseData(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getWebpage/terms-conditions`,
      {}
    );
  }

  getLiveStreams(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getLiveStreamPageData`,
      {}
    );
  }
}
