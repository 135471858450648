<div class="content-body mt-menu">
  <!-- <div class="spinner" *ngIf="!showList">
        <mat-progress-spinner
        color="warn"
        mode="indeterminate">
        </mat-progress-spinner>
    </div> -->

  <div class="ph-item" *ngIf="!showList">
    <div class="ph-col-12">
      <div class="ph-row mb-2 mt-2">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-10 empty big"></div>
      </div>
      
      <div class="row">
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
      </div>
    </div>
      <div class="ph-row mb-2 mt-2">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-10 empty big"></div>
      </div>
      
      <div class="row">
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
      </div>
    </div>
      <div class="ph-row mb-2 mt-2">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-10 empty big"></div>
      </div>
      
      <div class="row mb-2">
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
      </div>
    </div>
      <div class="ph-row mb-2 mt-2">
        <div class="ph-col-2 big"></div>
        <div class="ph-col-10 empty big"></div>
      </div>
      
      <div class="row">
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
        </div>
        <div class="col-md-2">
          <div class="ph-picture"></div>
      </div>
    </div>
</div>
</div>

    <div class="row" *ngIf="showList">
      <div class="col-md-12" *ngFor="let item of list">
        <div class="slider-heading-section" *ngIf="item.contents.length > 0">
          <h4>{{ item.name }}</h4>
          <app-single-video-slider
            [playlist]="item.contents"
            [sliderConfig]="sliderConfig"
          ></app-single-video-slider>
        </div>
      </div>
    </div>
</div>
