import { Component } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  isSidebarActive: boolean = false;

  toggleSidebar(event: Event) {
    event.preventDefault();
    this.isSidebarActive = !this.isSidebarActive;
  }

  closeSidebar(event: Event) {
    event.preventDefault();
    this.isSidebarActive = false;
  }

}
