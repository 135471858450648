import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SingleScreenComponent } from './components/single-screen/single-screen.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { SearchComponent } from './components/search/search.component';
import { CategoryComponent } from './components/category/category.component';
import { UpcomingComponent } from './components/upcoming/upcoming.component';
import { TermofsericeComponent } from './components/termofserice/termofserice.component';
import { LivestreamComponent } from './components/livestream/livestream.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'video/:id',
    component: SingleScreenComponent,
  },
  {
    path: 'category',
    component: CategoryComponent,
  },
  {
    path: 'upcoming',
    component: UpcomingComponent,
  },
  {
    path: 'live',
    component: LivestreamComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'terms-of-use',
    component: TermofsericeComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
