import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MovieService } from 'src/app/services/movie.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Campaign } from 'src/app/models/campaign.model';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sticky = false;
  showerrorBlock = false;
  showerror = false;
  showNoUserError = false;
  showPasswordError = false;
  isLogged = false;
  showAlreadyExistEroor = false;
  wentWrong = false;
  showSignin = true;
  profileimg = '';
  modalRef: BsModalRef;
  @ViewChild('template') template;

  textPhoneNumber = '';
  showWrongNumber = false;
  sendCode = false;
  dialCode = '92';
  showPhoneEnter = true;
  numberEmpty = false;
  tryagain = false;
  showInvalidCode = false;
  fullNumber = '';
  windowRef: any;
  verificationID = '';
  codeText = '';
  errorText = '';
  isSidebarActive: boolean = false;
  showLoading = false;


  campaign : Observable<Campaign>;
  campaignValue: any;

  public signinForm = new UntypedFormGroup({
    email: new UntypedFormControl('',  [Validators.required, Validators.email]),
    password: new UntypedFormControl('',  Validators.required),

  });

  public signUpForm = new UntypedFormGroup({
    username: new UntypedFormControl('',  Validators.required),
    email: new UntypedFormControl('',  [Validators.required, Validators.email]),
    password: new UntypedFormControl('',  Validators.required),
  });

  public passrestForm = new UntypedFormGroup({
    email: new UntypedFormControl('',  [Validators.required, Validators.email]),
  });

  constructor(
    private movie: MovieService,
    private modalService: BsModalService,
    public router: Router,
    private cdRef:ChangeDetectorRef,
    private win: WindowService,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) { }


  ngOnInit(): void {

    this.movie.loginMenuChanges.subscribe(x => {
      if(x == 'open'){
          this.openLogin();
      }
    })

    if (isPlatformBrowser(this.platformId)) {

      this.movie.checkLogin();

      this.movie.userDataChanges.subscribe(x => {
         if(x){
           this.isLogged = true;
         }else{
          this.isLogged = false;
         }
      })

    }
  }

  toggleSidebar(event: Event) {
    event.preventDefault();
    this.isSidebarActive = !this.isSidebarActive;
  }


  onCountryChange(e){
    this.dialCode = e.dialCode;
  }


  loginuser(formData: FormData){

    if(!this.showLoading){
      this.showerrorBlock = false;
      this.showLoading = true;
  
      var data = {
        email : formData['email'],
        password : formData['password'],
        device_name: 'web'
      }
      this.movie.firebaseLogin(data).subscribe((resp) => {
  
        this.movie.getLoginUser().subscribe((res) => {
  
          if(res.code == 200){
            localStorage.setItem('margalla-profile', JSON.stringify(res.data));
            this.signinForm.reset();
            this.movie.triggerStatusChange(res.data);
            this.modalRef.hide();
            this.showLoading = false;
          }else{
            this.showerrorBlock = true;
            this.showLoading = false;
            this.errorText = res.message;
          }
        },err => {
          this.showerrorBlock = true;
          this.showLoading = false;
          this.errorText = 'Something went wrong, please try again.';
        })
         
      
      },err => {
        this.showerrorBlock = true;
        this.showLoading = false;
        this.errorText = 'Something went wrong, please try again.';
        console.log(err)
      })
    }
 

  }


  loginGoogleUser(){

    if(!this.showLoading){
      this.showerrorBlock = false;
      this.showLoading = true;
      this.cdRef.detectChanges()
      this.movie.googleSignin().subscribe((resp) => {
  
        this.movie.getLoginUserSocial().subscribe((res) => {
  
          if(res.code == 200){
            localStorage.setItem('margalla-profile', JSON.stringify(res.data));
            this.signinForm.reset();
            this.movie.triggerStatusChange(res.data);
            this.modalRef.hide();
            this.showLoading = false;
            this.cdRef.detectChanges()
          }else{
            this.showerrorBlock = true;
            this.showLoading = false;
            this.errorText = res.message;
            this.cdRef.detectChanges()
          }
        },err => {
          this.showerrorBlock = true;
          this.showLoading = false;
          this.errorText = 'Something went wrong, please try again.';
          this.cdRef.detectChanges()
        })
         
      
      },err => {
        this.showLoading = false;
        if(err.code == 'auth/popup-closed-by-user'){

        }else{
          this.showerrorBlock = true;
          this.errorText = 'Something went wrong, please try again.';
          console.log(err)
          
        }
        this.cdRef.detectChanges()
      })
    }
 

  }

  openLogin()
  {
    this.modalRef = this.modalService.show(this.template);
        this.modalRef.setClass("full-screen-modal");
        this.isSidebarActive = false;
       
  }

  logout()
  {
    this.movie.logout().subscribe(res => {
    })
    
    localStorage.removeItem("margalla-profile");
    this.movie.triggerStatusChange(null);
    this.isSidebarActive = false;
  }

  showSignupWindow()
  {
    this.showSignin = false;
    this.signinForm.reset();
  }

  showSignupInWindow()
  {
    this.showSignin = true;
    this.signUpForm.reset();
  }

  goto(link){
    this.router.navigate([link]);
    this.isSidebarActive = false;
  }

  closeSidebar(event: Event) {
    event.preventDefault();
    this.isSidebarActive = false;
  }


  signupuser(formData: FormData){
    this.wentWrong = false;
        this.showAlreadyExistEroor = false;
    // this.movie.signUp(formData['email'], formData['password']).then(res => {
    //         // this.afAuth.currentUser.sendEmailVerification().then(res => {
              
    //         // })

    //        var data = {
    //           "name": formData['username'],
    //           'source' : this.campaignValue ? this.campaignValue[0].source ? this.campaignValue[0].source : 'direct' : 'direct',
    //           'campaign_slug' : this.campaignValue ? this.campaignValue[0].campaign_slug ? this.campaignValue[0].campaign_slug : null : null,
    //           'referrer_id': this.campaignValue ? this.campaignValue[0].referrer_id ? this.campaignValue[0].referrer_id : null : null,
    //           'page': window.location.href,
    //           'platform': 'web'
    //           } 

    //         this.movie.makeProfile(data).subscribe(res => {
    //           this.signinForm.reset();
    //           this.signUpForm.reset();
    //           localStorage.setItem('dew-profile', JSON.stringify(res.data));
    //           this.movie.triggerStatusChange();
    //           this.modalRef.hide();
    //         });
            
    // })
    // .catch(err => {
    //   if(err.code == 'auth/email-already-in-use'){
    //     this.showAlreadyExistEroor = true;
    //     this.wentWrong = false;
    //     this.cdRef.detectChanges();
    //   }else if(err.code == 'auth/account-exists-with-different-credential'){
    //     this.showAlreadyExistEroor = true;
    //     this.wentWrong = false;
    //     this.cdRef.detectChanges();
    //   }else{
    //     this.wentWrong = true;
    //     this.showAlreadyExistEroor = false;
    //     this.cdRef.detectChanges();
    //   }
    // })
   
    // this.router.navigate(["/profile"])
  }




  closeLoginWindow()
  {
    this.tryagain = false;
    this.sendCode = false;
    this.showPhoneEnter = true;
    this.showWrongNumber = false;
    this.numberEmpty = false;
    this.showInvalidCode = false;
    this.codeText = '';
    this.fullNumber = '';
    this.textPhoneNumber = '';
    this.verificationID = '';
    this.errorText = '';
    this.showerrorBlock = false;
    this.modalRef.hide();
  }

  goBackBtn()
  {
    this.tryagain = false;
    this.sendCode = false;
    this.showPhoneEnter = true;
    this.showWrongNumber = false;
    this.numberEmpty = false;
    this.showInvalidCode = false;
    this.codeText = '';
    this.fullNumber = '';
    this.textPhoneNumber = '';
    this.verificationID = '';
    this.errorText = '';
    this.showerrorBlock = false;
  }

}
